import React from 'react';
import { Link } from '@inertiajs/react';

const Error = ({ status }) => {
  const title = {
    503: 'Service Unavailable',
    500: 'Server Error',
    404: 'Page Not Found',
    403: 'Forbidden',
  }[status]

  const description = {
    503: 'Sorry, we are doing some maintenance. Please check back soon.',
    500: 'Whoops, something went wrong on our servers.',
    404: 'Sorry, the page you are looking for could not be found.',
    403: 'You don\'t have permission to access this page.'
  }[status]
  return (
    <div className="flex items-center justify-center h-screen bg-gray-50">
      <div className="flex flex-col text-center">
        <h1 className="text-9xl font-extrabold text-red-500">{status}</h1>
        <p className="text-2xl font-semibold text-gray-800 mt-4">
          {title}
        </p>
        <p className="text-lg text-gray-600 mt-2">
          {description}
        </p>
        <Link href={window.route('login')} className="mt-3 mx-auto w-40 py-1 rounded-[5px] font-semibold cursor-pointer whitespace-nowrap hover:opacity-90 h-10 px-8 text-white bg-gradient-to-b from-gradient-blue-light to-gradient-blue-dark flex items-center justify-center">
          Go Back
        </Link>
      </div>
    </div>
  );
};

export default Error;
